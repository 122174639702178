

































import { UserActivationActionType } from '@/includes/types/PmConfig.types'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  data() {
    return {
      UserActivationActionType
    }
  }
})
export default class ActivationActionTabs extends Vue {
  @Prop({ required: true , default: null }) initialTabType!:UserActivationActionType | null

  @Prop({ type: Boolean, required: false, default: true }) removable!: boolean | null

  @Prop() disabled!:boolean

  activeTabKey:UserActivationActionType | null = UserActivationActionType.Message

  @Emit('tabTypeClick')
  setActiveTabType(tabType: UserActivationActionType | null):UserActivationActionType | null{
    this.activeTabKey = tabType
    return tabType
  }

  created():void {
    this.activeTabKey = this.initialTabType
  }
}
