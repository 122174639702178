



























































import { TriggerFactory } from '@/components/Triggers/trigger-factory'
import ConditionGroup from '@/views/board-settings/Actions/ConditionGroup.vue'
import ActivationActionData from '@/components/BoardPmConfig/ActivationActionData.vue'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { TriggerData, TriggerDataCondition } from '@/components/Triggers/types'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import FieldTitle from 'piramis-base-components/src/components/Pi/components/FieldTitle.vue'

import Component from 'vue-class-component'
import { Prop, VModel } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    ActivationActionData,
    ConditionGroup,
    EmptyData,
    FieldTitle
  },
  data() {
    return {
      BaseItemViewAction
    }
  }
})
export default class ActionSetup extends Vue {
  @VModel() actionData!: TriggerData

  @Prop() viewType!: BaseItemViewAction

  triggerFactory: TriggerFactory = new TriggerFactory()

  conditionGroupByGuid(conditionGroupGuid:string):any {
    return this.actionData.conditions.find(group => group.guid === conditionGroupGuid)
  }

  onConditionDelete(data: { conditionGuid: string, conditionGroupGuid: string }): void {
    const conditionGroup = this.conditionGroupByGuid(data.conditionGroupGuid)
    conditionGroup.value = conditionGroup.value.filter(c => c.guid !== data.conditionGuid)
  }

  onConditionSelect(data: { condition: TriggerDataCondition, conditionGroupGuid: string }): void {
    const conditionGroup = this.conditionGroupByGuid(data.conditionGroupGuid)

    conditionGroup.value.push(this.triggerFactory.createCondition(data.condition))
  }

  onConditionGroupDelete(data: { conditionGroupGuid:string }): void {
    this.actionData.conditions = this.actionData.conditions.filter(conditionGroup => conditionGroup.guid !== data.conditionGroupGuid)
  }

  addConditionGroup(): void {
    this.actionData.conditions.push(this.triggerFactory.createConditionsGroup())
  }
}
