



















































import ActionConditionsMixin from '@/views/board-settings/Actions/ConditionViews/ActionConditionsMixin'
import ConditionItem from '@/views/board-settings/Actions/ConditionItem.vue'
import { IterableListItem, TriggerDataCondition } from '@/components/Triggers/types'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import { Entity } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    ConditionItem,
    DefaultList,
    EmptyData
  }
})
export default class ConditionGroup extends Mixins<ActionConditionsMixin>(ActionConditionsMixin) {
  @Prop() groupData!: IterableListItem<Array<TriggerDataCondition>>

  @Prop() disabled!:boolean

  @Emit()
  deleteCondition(conditionGuid: string): { conditionGuid: string, conditionGroupGuid: string } {
    return { conditionGuid, conditionGroupGuid: this.groupData.guid }
  }

  @Emit()
  deleteConditionGroup(groupGuid:string): {conditionGroupGuid:string }  {
    return { conditionGroupGuid: groupGuid }
  }

  @Emit('conditionSelect')
  select(selectedItem: TriggerDataCondition): {condition: TriggerDataCondition, conditionGroupGuid: string } {
    this.isPopupOpen = false
    return { condition: selectedItem, conditionGroupGuid: this.groupData.guid }
  }

  isPopupOpen = false

  modalGuid = ''

  get defaultListItems(): Array<Entity> {
    return [ { items: cloneDeep(this.conditions) } ]
  }

  openModal(guid:string): void {
    this.isPopupOpen = true
    this.modalGuid = guid
  }
}
