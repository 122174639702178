


























import ActionSetup from '@/components/Triggers/ActionSetup.vue'
import { TriggerFactory } from '@/components/Triggers/trigger-factory'
import { errorNotification } from '@/includes/services/NotificationService'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import PageTitle from '@/components/PageTitle.vue'
import { TriggerData } from '@/components/Triggers/types'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    ActionSetup,
    PageTitle
  }
})
export default class AutoReplyActionsItem extends Vue {
  actionData = {} as TriggerData

  triggerFactory: TriggerFactory = new TriggerFactory()

  save():void {
    if (this.viewType === BaseItemViewAction.Edit) this.editAction()
    if (this.viewType === BaseItemViewAction.New) this.createAction()
  }

  get viewType():BaseItemViewAction {
    return this.$route.params.actionType.toString() as BaseItemViewAction
  }

  async createAction():Promise<void> {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.boardsState.activeBoard!.config.pm_config.user_auto_reply_actions.push(this.triggerFactory.triggerDataToRawTriggerData(this.actionData))
      },
    });

    await this.$store.dispatch('savePostBoardConfig')
    await this.$router.back()
  }

  async editAction(): Promise<void> {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        const idx = this.$store.state.boardsState.activeBoard!.config.pm_config.user_auto_reply_actions.findIndex(a => a.guid === this.actionData!.guid)

        this.$store.state.boardsState.activeBoard!.config.pm_config.user_auto_reply_actions.splice(idx, 1, this.triggerFactory.triggerDataToRawTriggerData(this.actionData))
      },
    });

    await this.$store.dispatch('savePostBoardConfig')
    await this.$router.back()
  }

  requestFlow():void {
    if (!this.$store.state.flowsState.flows) {
      this.$store.dispatch('requestFlows', { board_key: this.$store.getters.getBoardById(this.$route.params.id).board })
    }
  }

  gotoList():void {
    this.$router.push({
      name: 'Auto_reply_actions'
    })
  }

  created():void {
    this.requestFlow()

    if (this.$store.state.tempTrigger) {
      this.actionData = this.$store.state.tempTrigger
      this.$store.commit('reset_temp_trigger')
    } else {
      if (this.viewType === BaseItemViewAction.New) {
        this.actionData = this.triggerFactory.createNewTrigger()
      } else if (this.viewType === BaseItemViewAction.Edit) {
        const actionId = this.$route.params.actionId
        const replyActions = this.$store.state.boardsState.activeBoard?.config.pm_config?.user_auto_reply_actions
        const actionItem = replyActions?.find(action => action.guid === actionId)

        if (actionItem) {
          this.actionData = this.triggerFactory.createNewTriggerFromExists(actionItem)
        } else {
          this.gotoList()
          errorNotification('Reply action not found')
          throw new Error(`Reply action not found, got: ${ actionId }`)
        }
      } else {
        this.gotoList()
        errorNotification('Page not found')
      }
    }
  }
}
