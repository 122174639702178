var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activation-action-data"},[_c('activation-action-tabs',{attrs:{"initial-tab-type":_vm.actionData && _vm.actionData.type,"removable":_vm.removable,"disabled":_vm.disabled},on:{"tabTypeClick":_vm.toggleActivationActionModelByType}}),(_vm.actionData)?_c('div',{staticClass:"activation-action-data__action-data"},[(_vm.actionData.type === _vm.UserActivationActionType.Message)?_c('div',{staticClass:"activation-action-data__message"},[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.actionData,
            'key': 'variants',
            'hasMedia': true,
            'targetToUpload': function () {
              return {
                'target': 'target',
                'target_id': _vm.$store.state.boardsState.activeBoard.board
              }
            },
            'base-api-url': 'api.presscode.app/cabinet',
            'disabled': _vm.disabled,
            'availableButtonsTypes': _vm.pmButtons,
            'buttonsHelpView': _vm.EditorButtonsHelpView,
          }
        }}})],1):_vm._e(),(_vm.actionData.type === _vm.UserActivationActionType.Flow)?_c('div',{staticClass:"activation-action-data__flow"},[_c('select-input',{staticClass:"activation-action-data__flow-select",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.actionData,
            'key': 'flow',
            'options': _vm.$store.getters.flowOptions,
            'clearable': false,
            'disabled': _vm.disabled
          }
        }}}),(_vm.actionData.flow !== null)?_c('div',{staticClass:"activation-action-data__flow-info"},[_c('img',{staticClass:"activation-action-data__flow-preview",attrs:{"src":("https://api.presscode.app/thumbnail/flow/" + (_vm.actionData.flow)),"alt":"flow-preview"}}),_c('a-button',{staticClass:"mt-2",attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.gotoFlow(_vm.actionData.flow)}}},[_vm._v(" Перейти к сценарию ")])],1):_vm._e()],1):_vm._e()]):_c('div',{staticClass:"mt-5"},[_vm._t("no-action-info")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }