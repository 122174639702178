






















import { IterableListItem, TriggerDataCondition } from '@/components/Triggers/types'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class ConditionItem extends Vue {
  @Prop() condition!:IterableListItem<TriggerDataCondition>

  @Prop() disabled!:boolean

  @Emit()
  deleteCondition(conditionGuid:string): string {
    return conditionGuid
  }
}
