





















































































import {
  UserActivationAction,
  UserActivationActionFlow,
  UserActivationActionMessage,
  UserActivationActionType
} from '@/includes/types/PmConfig.types'
import { InputSetups } from '@/mixins/input-setups'
import ActivationActionTabs from '@/components/BoardPmConfig/ActivationActionTabs.vue'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ActivationActionTabs,
    ConfigField
  },
  data() {
    return {
      UserActivationActionType,
      EditorButtonsHelpView
    }
  }
})
export default class ActivationActionData extends Mixins(UseFields, InputSetups) {
  @VModel({ required:true }) actionData!: UserActivationAction | null

  @Prop({ type: Boolean, required: false, default: true }) removable!: boolean | null

  @Prop() disabled!:boolean

  get activationActionMessage(): UserActivationActionMessage {
    return {
      type: UserActivationActionType.Message,
      variants: [ {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
      } ]
    }
  }

  get activationActionFlow(): UserActivationActionFlow {
    return {
      type: UserActivationActionType.Flow,
      flow: this.$store.getters.flowOptions[0]?.value ?? null,
      output: 0
    }
  }

  toggleActivationActionModelByType(type: UserActivationActionType | null): void {
    if (type === UserActivationActionType.Message) {
      this.actionData = this.activationActionMessage
    }
    if (type === UserActivationActionType.Flow) {
      this.actionData = this.activationActionFlow
    }
    if (type === null) {
      this.actionData = null
    }
  }

  setBaseMessageAction(): void {
    this.actionData = this.activationActionMessage
  }

  gotoFlow(flow_key:string):void {
    this.$router.push({
      name: 'Flow',
      params: {
        id: this.$route.params.id,
        actionType: 'edit'
      },
      query: { flow_key }
    })
  }
}
