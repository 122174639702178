import { TriggerDataCondition } from '@/components/Triggers/types'
import { AutoReplyConditionType } from '@/includes/types/PmConfig.types'
import i18n from '@/i18n/i18n'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { snakeCase } from 'lodash'

@Component
export default class ActionConditionsMixin extends Vue {
    get conditions():Array<TriggerDataCondition> {
        return [
            {
                title: i18n.t(`field_${ snakeCase(AutoReplyConditionType.Equals) }_title`).toString(),
                key: AutoReplyConditionType.Equals,
                view: () => import('../ConditionViews/Equals.vue'),
                struct: {
                    type: AutoReplyConditionType.Equals,
                    values: [],
                    invert: false
                }
            },
            {
                title: i18n.t(`field_${ snakeCase(AutoReplyConditionType.WordEquals) }_title`).toString(),
                key: AutoReplyConditionType.WordEquals,
                view: () => import('../ConditionViews/WordEquals.vue'),
                struct: {
                    type: AutoReplyConditionType.WordEquals,
                    values: [],
                    invert: false
                }
            },
            {
                title: i18n.t(`field_${ snakeCase(AutoReplyConditionType.EndsWith) }_title`).toString(),
                key: AutoReplyConditionType.EndsWith,
                view: () => import('../ConditionViews/EndsWith.vue'),
                struct: {
                    type: AutoReplyConditionType.EndsWith,
                    values: [],
                    invert: false
                }
            },
            {
                title: i18n.t(`field_${ snakeCase(AutoReplyConditionType.StartsWith) }_title`).toString(),
                key: AutoReplyConditionType.StartsWith,
                view: () => import('../ConditionViews/StartsWith.vue'),
                struct: {
                    type: AutoReplyConditionType.StartsWith,
                    values: [],
                    invert: false
                }
            },
            {
                title: i18n.t(`field_${ snakeCase(AutoReplyConditionType.Contains) }_title`).toString(),
                key: AutoReplyConditionType.Contains,
                view: () => import('../ConditionViews/Contains.vue'),
                struct: {
                    type: AutoReplyConditionType.Contains,
                    values: [],
                    invert: false
                }
            }
        ]
    }

    get conditionOptions():Array<SelectOption> {
        const baseConditions:Array<SelectOption> = this.conditions
            .slice()
            .map(value => ({
            label: value.title,
            value: value.key,
        }))

        baseConditions.push({
            label: 'extra_trigger_setting_option',
            value: 'EXTRA'
        })

        return baseConditions
    }
}
