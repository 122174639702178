import { IterableItemFactory } from './iterable-item-factory'
import {
    IterableListItem,
    RawTriggerData,
    TriggerData,
    TriggerDataCondition
} from '@/components/Triggers/types'
import {
    AutoReplyConditionType, ContainsCondition,
    UserActivationActionMessage,
    UserActivationActionType
} from '@/includes/types/PmConfig.types'
import ActionConditionsMixin from '@/views/board-settings/Actions/ConditionViews/ActionConditionsMixin'

import { cloneDeep } from 'lodash'
import { Guid } from 'guid-typescript'

export class TriggerFactory extends ActionConditionsMixin{

    iterableItemFactory: IterableItemFactory = new IterableItemFactory()

    getTriggerTemplate(): any {
        return {
            guid: Guid.create().toString(),
            conditions: [ this.conditionGroupTemplate ],
            action: this.defaultAction,
        }
    }

    get defaultAction(): UserActivationActionMessage {
        return {
            type: UserActivationActionType.Message,
            variants: [ { text: 'Текст сообщения, который будет отправлен. Задайте собственный' } ]
        }
    }

    get conditionGroupTemplate(): Array<ContainsCondition> {
        return [ {
                type: AutoReplyConditionType.Contains,
                values: [],
                invert: false
        } ]
    }

    createConditionsGroup<T>(value?: Array<IterableListItem<T>>): IterableListItem<Array<IterableListItem<T>>> {
        return this.iterableItemFactory.create<Array<IterableListItem<T>>>(value || [])
    }

    createCondition<T>(value: T): IterableListItem<T> {
        return this.iterableItemFactory.create(cloneDeep(value))
    }

    baseConditionStructure(condition:any):TriggerDataCondition {
        const type = condition.type

        return this.conditions.find(c => c.key === type)!
    }

    processRawTriggerDataForFrontend(rawTriggerData: RawTriggerData): TriggerData {
        const action = cloneDeep(rawTriggerData)

        const triggerAction = action.action;
        const triggerConditions = action.conditions.map(conditions => {
            return this.createConditionsGroup(conditions.map(condition => {
                const conditionTemplate = this.baseConditionStructure(condition)
                conditionTemplate.struct = condition

                return this.createCondition(conditionTemplate)
            }))
        })

        const actionGuid = action.guid

        return { guid: actionGuid, action: triggerAction, conditions: triggerConditions }
    }

    triggerDataToRawTriggerData(actionData: TriggerData): RawTriggerData {
        const actionDataCopy = cloneDeep(actionData)

        const triggerGuid = actionData.guid
        const triggerAction = actionData.action;
        const triggerConditions = actionDataCopy.conditions.map(conditionGroup => conditionGroup.value.map(condition => condition.value.struct))

        return { guid: triggerGuid, action: triggerAction, conditions: triggerConditions }
    }

    createNewTrigger(): TriggerData {
        return this.processRawTriggerDataForFrontend(cloneDeep(this.getTriggerTemplate()))
    }

    createNewTriggerFromExists(triggerData: RawTriggerData): TriggerData {
        return this.processRawTriggerDataForFrontend(cloneDeep(triggerData))
    }
}
